import React from "react"
import Layout from "../components/layout"
import BreadCrumbs from '../components/breadcrumbs'
import SEO from '../components/seo'
import { graphql } from "gatsby"

class LandingTemplate extends React.Component {
  render() {
    const { markdownRemark } = this.props.data
    const { frontmatter, html } = markdownRemark
    const { siteUrl } = this.props.data.site.siteMetadata

    const ogImage = `${siteUrl}/images/software-wagon.png`;

    return (
      <Layout location={this.props.location}>
        <SEO
          title={frontmatter.title}
          description={frontmatter.description}
          keywords={frontmatter.keywords.split(`, `)}
          meta={[
            {
              property: 'og:image',
              content: ogImage,
            },
            {
              property: 'twitter:image',
              content: ogImage,
            },
            {
              property: 'og:url',
              content: `${this.props.data.site.siteMetadata.siteUrl}${frontmatter.path}`
            }
          ]}
        />

        <div className="flex relative items-center lg:pt-0 pt-10">
          <div className="absolute pin-l pin-t lg:pin-none">
            <BreadCrumbs>{frontmatter.title}</BreadCrumbs>
          </div>

          <h1 className="flex-1 text-center font-normal text-darkest">
            {frontmatter.title}
          </h1>
        </div>

        <div className="flex mt-10">
          <div className="flex flex-col shadow-card h-full bg-white rounded-b w-full">
            <div className="flex flex-col p-5 h-full">
              <div
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default LandingTemplate

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
        keywords
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
